export const getBuildingType = (id: number): string => {
    switch (id) {
        case 1: return "Bungalow";
        case 2: return "Chalet";
        case 3: return "Kasteel";
        case 4: return "Boerderij";
        case 5: return "Hoeve";
        case 6: return "Bel-etage";
        case 7: return "Herenhuis";
        case 8: return "Villa";
        case 9: return "Kantoorruimte";
        case 10: return "Bedrijfsgebouw";
        case 11: return "Handelspand";
        case 12: return "Appartement";
        case 13: return "Duplex";
        case 14: return "Loft";
        case 15: return "Penthouse";
        case 16: return "Studio";
        case 17: return "Bosgrond";
        case 18: return "Recreatiegrond";
        case 19: return "Landbouwgrond";
        case 20: return "Garage";
        case 21: return "Project";
        case 22: return "Magazijn";
        case 23: return "Praktijkruimte";
        case 24: return "Bouwgrond";
        case 25: return "Fermette";
        case 26: return "Huis";
        case 1721: return "Assistentiewoning";
        case 1975: return "Parking";
        case 1976: return "Staanplaats";
        case 1977: return "Hoekwoning";
        case 1978: return "Rijwoning";
        case 1979: return "Opslagplaats";
        default: return "Onbekend gebouw type";
    }
};

export const getEpcLabel = (kwh: number) => {
    if (kwh <= 100) {
        return "A";
    } else if (kwh <= 200) {
        return "B";
    } else if (kwh <= 300) {
        return "C";
    } else if (kwh <= 400) {
        return "D";
    } else if (kwh <= 500) {
        return "E";
    } else {
        return "F";
    }
};


export const getStatusName = (transaction_id: number) => {
    switch (transaction_id) {
        case 1:
            return "TE_KOOP";
        case 2:
            return "TE_HUUR";
        case 3:
            return "OPTIE_KOOP";
        case 4:
            return "OPTIE_HUUR";
        case 5:
            return "VERKOCHT";
        case 6:
            return "VERHUURD";
        case 7:
            return "UIT_MANDAAT_KOOP";
        case 8:
            return "UIT_MANDAAT_HUUR";
        case 9:
            return "LIJFRENTE";
        case 10:
            return "OVERNAME";
        case 11:
            return "PROSPECTIE";
        case 12:
            return "SYNDIC";
        case 31:
            return "WAARDEBEPALING";
        default:
            return "ONBEKEND";
    }
};


export const getGardenDirection = (direction_id: number) => {
    switch (direction_id) {
        case 1:
            return "Noord";
        case 2:
            return "Zuid";
        case 3:
            return "Oost";
        case 4:
            return "Noordoost";
        case 5:
            return "Zuidoost";
        case 6:
            return "West";
        case 7:
            return "Noordwest";
        case 8:
            return "Zuidwest";
        default:
            return "ONBEKEND";
    }
};

// Stedenkundige bestemming
export const getTownPlannings = (town_planning_id: number) => {
    switch (town_planning_id) {
        case 1:
            return "Agrarisch gebied";
        case 2:
            return "Gebied met economische activiteiten";
        case 3:
            return "Winningsgebied";
        case 4:
            return "Grondreservegebied";
        case 5:
            return "Industriegebied";
        case 6:
            return "West";
        case 7:
            return "Recreatiegebied";
        case 8:
            return "Gemengd woongebied"; 
        case 9:
            return "Natuurgebied"; 
        case 10:
            return "Parkgebied"; 
        case 11:
            return "Woongebied";
        case 12:
            return "Groengebied";
        case 13:
            return "Woonuitbreidingsgebied";
        case 14:
            return "Woonparken";
        case 15:
            return "Woongebied met landelijk karakter";
        case 16:
            return "Woongebied met cult., historische en/of esthetische waarde"; 
        case 17:
            return "Landschappelijk waardevol agrarisch gebied";
        case 18:
        return "In aanvraag";
        case 19:
            return "Industriegebied voor ambachtelijke bedrijven of gebieden voor kleine en middelgrote ondernemingen";
        case 20:
            return "Gebied voor dagrecreatie"; 
        case 21:
            return "Gebied voor verblijfsrecreatied";
        case 22:
            return "Natuurreservaat";
        case 32:
            return "Woongebied met recreatief karakter";
        case 33:
            return "Gebieden voor gemeenschapsvoorzieningen en openbaar nut"; 
        case 34:
            return "Dienstverleningsgebied";
        case 38:
            return "Watergevoelig Openruimtegebied";
        default:
            return "ONBEKEND";
    }
};


export const getTownValidation = (town_planning_violation_id: number) => {
    switch (town_planning_violation_id) {
        case 0:
            return "Geen";
        case 1:
            return "Dagvaarding uitgebracht";
        case 2:
            return "Rechterlijke herstelmaatregel";
        case 3:
            return "Bestuursdwang opgelegd";
        case 4:
            return "Last onder dwangsom opgelegd";
        case 5:
            return "Minnelijke schikking aangegaan";
        default:
            return "ONBEKEND";
    }
};

export const getBuildingLicense = (building_license_id: number) => {
    switch (building_license_id) {
        case 1:
            return "Bouw vergund";
        case 2:
            return "Geen bouwvergunning";
        case 3:
            return "Gebouwd voor 1962";
        case 4:
            return "In aanvraag";
        default:
            return "ONBEKEND";
    }
};