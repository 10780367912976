import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import PropertyList from './components/OverviewPage';
import DetailPage from './components/DetailPage';

const overviewContainer = document.getElementById('react-overview-page');
if (overviewContainer) {
  const root = ReactDOM.createRoot(overviewContainer);
  root.render(<PropertyList />);
}

const detailContainer = document.getElementById('react-detail-page');

if (detailContainer) {
  const propertyId = detailContainer.getAttribute('data-id') || '';
  const root = ReactDOM.createRoot(detailContainer);
  root.render(<DetailPage propertyId={propertyId} />);
}

const App: React.FC = () => {
  return (
      <DetailPage propertyId={"3906006"} />
 // <PropertyList />
  );
};

export default App;
